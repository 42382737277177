const IconColumn = ({ size = 6, color = 'gray' }) => (
  // <svg className={`w-${size} h-${size} text-${color}-600`} viewBox="0 0 502 502" enableBackground="new 0 0 502 502" fill="currentColor">
	// 	<g>
	// 		<path d="M10,140.444c5.522,0,10-4.477,10-10V82.222c0-5.523-4.478-10-10-10s-10,4.477-10,10v48.222
	// 			C0,135.967,4.478,140.444,10,140.444z"/>
	// 		<path d="M10,429.778c5.522,0,10-4.477,10-10v-48.222c0-5.523-4.478-10-10-10s-10,4.477-10,10v48.222
	// 			C0,425.301,4.478,429.778,10,429.778z"/>
	// 		<path d="M10,236.889c5.522,0,10-4.477,10-10v-48.223c0-5.523-4.478-10-10-10s-10,4.477-10,10v48.223
	// 			C0,232.412,4.478,236.889,10,236.889z"/>
	// 		<path d="M10,333.333c5.522,0,10-4.477,10-10v-48.222c0-5.523-4.478-10-10-10s-10,4.477-10,10v48.222
	// 			C0,328.856,4.478,333.333,10,333.333z"/>
	// 		<path d="M10,44c5.522,0,10-4.477,10-10V20h14c5.522,0,10-4.477,10-10S39.522,0,34,0H10C4.478,0,0,4.477,0,10v24
	// 			C0,39.523,4.478,44,10,44z"/>
	// 		<path d="M371.556,20h48.223c5.522,0,10-4.477,10-10s-4.478-10-10-10h-48.223c-5.522,0-10,4.477-10,10S366.033,20,371.556,20z"/>
	// 		<path d="M178.667,20h48.223c5.522,0,10-4.477,10-10s-4.478-10-10-10h-48.223c-5.522,0-10,4.477-10,10S173.145,20,178.667,20z"/>
	// 		<path d="M82.223,20h48.223c5.522,0,10-4.477,10-10s-4.478-10-10-10H82.223c-5.522,0-10,4.477-10,10S76.7,20,82.223,20z"/>
	// 		<path d="M275.111,20h48.223c5.522,0,10-4.477,10-10s-4.478-10-10-10h-48.223c-5.522,0-10,4.477-10,10S269.589,20,275.111,20z"/>
	// 		<path d="M492,0h-24c-5.522,0-10,4.477-10,10s4.478,10,10,10h14v14c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10V10
	// 			C502,4.477,497.522,0,492,0z"/>
	// 		<path d="M492,265.111c-5.522,0-10,4.477-10,10v48.223c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10v-48.223
	// 			C502,269.588,497.522,265.111,492,265.111z"/>
	// 		<path d="M492,72.222c-5.522,0-10,4.477-10,10v48.222c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10V82.222
	// 			C502,76.699,497.522,72.222,492,72.222z"/>
	// 		<path d="M492,361.556c-5.522,0-10,4.477-10,10v48.222c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10v-48.222
	// 			C502,366.033,497.522,361.556,492,361.556z"/>
	// 		<path d="M492,168.667c-5.522,0-10,4.477-10,10v48.222c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10v-48.222
	// 			C502,173.144,497.522,168.667,492,168.667z"/>
	// 		<path d="M492,458c-5.522,0-10,4.477-10,10v14H20v-14c0-5.523-4.478-10-10-10s-10,4.477-10,10v24c0,5.523,4.478,10,10,10h482
	// 			c5.522,0,10-4.477,10-10v-24C502,462.477,497.522,458,492,458z"/>
	// 		<path d="M251,419.5c-5.522,0-10,4.477-10,10V457c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10v-27.5
	// 			C261,423.977,256.522,419.5,251,419.5z"/>
	// 		<path d="M251,184.5c5.522,0,10-4.477,10-10v-51c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10v51
	// 			C241,180.023,245.478,184.5,251,184.5z"/>
	// 		<path d="M261,327.5c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10v51c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10V327.5z"/>
	// 		<path d="M251,82.5c5.522,0,10-4.477,10-10V45c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10v27.5
	// 			C241,78.023,245.478,82.5,251,82.5z"/>
	// 		<path d="M35,251c0,5.523,4.478,10,10,10h27.5c5.522,0,10-4.477,10-10s-4.478-10-10-10H45C39.478,241,35,245.477,35,251z"/>
	// 		<path d="M327.5,241c-5.522,0-10,4.477-10,10s4.478,10,10,10h51c5.522,0,10-4.477,10-10s-4.478-10-10-10H327.5z"/>
	// 		<path d="M215.5,251c0,5.523,4.478,10,10,10H241v15.5c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10V261h15.5
	// 			c5.522,0,10-4.477,10-10s-4.478-10-10-10H261v-15.5c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10V241h-15.5
	// 			C219.978,241,215.5,245.477,215.5,251z"/>
	// 		<path d="M123.5,241c-5.522,0-10,4.477-10,10s4.478,10,10,10h51c5.522,0,10-4.477,10-10s-4.478-10-10-10H123.5z"/>
	// 		<path d="M419.5,251c0,5.523,4.478,10,10,10H457c5.522,0,10-4.477,10-10s-4.478-10-10-10h-27.5
	// 			C423.978,241,419.5,245.477,419.5,251z"/>
	// 	</g>
  // </svg>

  <svg className={`w-${size} h-${size} text-${color}-500`}  viewBox="0 0 231 231" enableBackground="new 0 0 231 231" fill="currentColor">
    <g>
      <path d="m.5,0v1 230h230v-230-1h-230zm33,67h65v49h-65v-49zm0,131v-49h65v49h-65zm164,0h-66v-49h66v49zm0-82h-66v-49h66v49z"/>
    </g>
  </svg>
);

export default IconColumn;
