// interface Props {
//   children: React.ReactNode;
//   type?: 'submit' | 'button' | 'reset';
//   onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
//   className?: string;
// }
export default function Button(props) {
  const { type = 'button', children, onClick, className } = props;

  return (
    <button
      className={`hover:bg-gray-800 text-sm hover:text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline ${className || 'bg-green-500 text-white'}`}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
