import Nav from './nav';
import ExtraInfo from './ExtraInfo.js';
import './style.css';

const Header = () => (
  <>
    <ExtraInfo />
    <header className="header">
      <Nav />
    </header>
  </>
);

export default Header;
