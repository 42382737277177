const IconSpinner = ({ className="h-5 w-28" }) => (
  <svg className={className} viewBox="0 0 20 20" preserveAspectRatio="xMinYMid">
    <g transform="translate(10 10)">
      <circle cx="0" cy="0" r="5" fill="var(--primary)">
        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
        />
      </circle>
    </g>
    <g transform="translate(30 10)">
      <circle cx="0" cy="0" r="5" fill="var(--primary)">
        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
        />
      </circle>
    </g>
    <g transform="translate(50 10)">
      <circle cx="0" cy="0" r="5" fill="var(--primary)">
        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
        />
      </circle>
    </g>
  </svg>
);

export default IconSpinner;