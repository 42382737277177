const IconDot = ({ size = 4, color = "gray", className = '' }) => (
  <svg
    className={`w-${size} h-${size} 
      ${color == 'gray' ? 'text-gray-500' : 'group-hover:text-primary'} 
      ${color == 'primary' ? 'text-primary' : 'group-hover:text-primary'} 
      ${color == 'green' ? 'text-green-500' : 'group-hover:text-primary'} 
      ${color == 'red' ? 'text-red-500' : 'group-hover:text-primary'} 
      ${color == 'blue' ? 'text-blue-500' : 'group-hover:text-primary'} 
      ${color == 'yellow' ? 'text-yellow-500' : 'group-hover:text-primary'} 
      ${color == 'purple' ? 'text-purple-500' : 'group-hover:text-primary'} 
      ${className}
    `}
    viewBox="0 0 15 15"
    fill="currentColor"
  >
    <g transform="scale(2.0,2.0)" transform-origin="center">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 9.125C8.39746 9.125 9.125 8.39746 9.125 7.5C9.125 6.60254 8.39746 5.875 7.5 5.875C6.60254 5.875 5.875 6.60254 5.875 7.5C5.875
          8.39746 6.60254 9.125 7.5 9.125ZM7.5 10.125C8.94975 10.125 10.125 8.94975 10.125 7.5C10.125 6.05025 8.94975 4.875 7.5 4.875C6.05025
          4.875 4.875 6.05025 4.875 7.5C4.875 8.94975 6.05025 10.125 7.5 10.125Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default IconDot;
