import { useEffect } from 'react';

const WindowZoom = () => {
  useEffect(() => {
    document.body.style.zoom = `${Math.round(100 / window.devicePixelRatio)}%`;
  }, []);

  return '';
};

export default WindowZoom;
