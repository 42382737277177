import CatalogHeader from '../../catalog/components/CatalogHeader.tsx';
import Footer from '../footer';

import ScrollArrow from '../scrollarrow';

const RouteCatalog = (props) => (
  <>
    <CatalogHeader />
    <div className="container flex flex-row items-stretch content-start justify-start flex-grow catalog">
      {props.children}
    </div>
    <Footer />
    <ScrollArrow />
    <div id="show-article" />
    <div id="show-modal" />
  </>
);

export default RouteCatalog;
