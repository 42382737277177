import './style.css';

const Page404 = () => (
  <div className="not-found">
    <div id="clouds">
      <div className="cloud x1" />
      <div className="cloud x1_5" />
      <div className="cloud x2" />
      <div className="cloud x3" />
      <div className="cloud x4" />
      <div className="cloud x5" />
    </div>
    <div className="c">
      <div className="_404">404</div>
      <hr />
      <div className="_1">STRONA</div>
      <div className="_2">NIE ZNALEZIONA</div>
      <a className="btn" href="/">STRONA GŁÓWNA</a>
    </div>
  </div>
);

export default Page404;
