const IconPdf = ({ size = 6, color = 'gray' }) => (
  <svg className={`w-${size} h-${size} text-${color}-600`} viewBox="796 796 200 200" enableBackground="new 796 796 200 200" fill="currentColor">
    <g>
      <g>
        <path d="M976.828,857.4l-56.941-56.941c-2.875-2.875-6.694-4.459-10.76-4.459H825.93c-8.394,0-15.218,6.828-15.218,15.222v169.56
          c0,8.393,6.824,15.219,15.218,15.219H966.07c8.389,0,15.218-6.826,15.218-15.219V868.162
          C981.288,864.099,979.705,860.274,976.828,857.4z M969.676,980.781c0,1.989-1.615,3.609-3.604,3.609H825.931
          c-1.989,0-3.605-1.62-3.605-3.609V811.221c0-1.988,1.616-3.605,3.605-3.605h79.409c2.174,0,3.936,1.763,3.936,3.936v42.938
          c0,7.25,5.876,13.126,13.123,13.126h43.342c1.045,0,2.045,0.415,2.783,1.152c0.738,0.738,1.152,1.739,1.152,2.783V980.781z"/>
      </g>
      <g>
        <path d="M844.083,970.6c0,0-0.001,0-0.002,0c-4.46-0.001-8.323-2.32-10.081-6.055c-1.975-4.193-0.958-9.353,2.72-13.805
          c5.031-6.09,14.36-11.574,29.288-17.199c3.111-5.742,5.751-11.331,7.673-16.244c3.406-8.708,5.765-15.099,7.711-20.9
          c-3.677-7.526-6.285-15.607-7.953-24.636c-1.261-6.824-0.151-12.751,3.126-16.688c2.321-2.79,5.625-4.389,9.063-4.389
          c3.338,0,6.55,1.515,8.815,4.157c3.036,3.543,4.197,8.794,3.269,14.786c-1.276,8.237-3.284,16.532-6.29,25.96
          c3.385,6.171,7.57,11.805,12.753,17.169c2.656,2.749,5.418,5.278,8.229,7.537c11.915-1.952,21.928-1.912,29.942,0.119
          c8.399,2.128,11.579,8.292,10.977,13.119c-0.581,4.66-4.679,9.363-12.521,9.363c-1.759,0-3.636-0.246-5.582-0.733
          c-8.749-2.186-17.216-6.223-25.196-12.011c-6.023,1.176-12.655,2.877-19.746,5.066c-5.432,1.679-11.671,3.634-17.823,5.844
          c-4.023,7.122-8.586,14.18-13.262,20.505C853.384,969.426,847.233,970.6,844.083,970.6z M857.996,947.002
          c-6.791,3.273-11.52,6.503-14.12,9.649c-1.467,1.775-1.8,3.258-1.479,3.938c0.214,0.456,0.844,0.729,1.685,0.729c0,0,0,0,0,0
          c0.881,0,4.033-0.381,7.646-5.27C853.856,953.171,855.96,950.132,857.996,947.002z M924.553,928.246
          c4.216,2.173,8.534,3.815,12.918,4.911c1.21,0.303,2.33,0.455,3.331,0.455c2.153,0,3.249-0.734,3.312-1.229
          c0.074-0.592-0.844-2.162-4.045-2.974C935.729,928.31,930.533,927.92,924.553,928.246z M879.339,928.634l-0.198,0.357
          c2.925-0.945,5.756-1.826,8.397-2.642c4.437-1.371,8.712-2.561,12.792-3.56l-0.361-0.277l0.736-0.132
          c-1.082-1.027-2.151-2.087-3.205-3.177c-3.726-3.856-6.992-7.846-9.858-12.048l-0.276,0.845l-0.128-0.246
          c-1.384,3.786-2.985,7.993-4.913,12.922c-1.008,2.576-2.196,5.317-3.531,8.157L879.339,928.634z M885.628,859.966
          c-0.684,0-1.369,0.37-1.929,1.043c-0.691,0.831-2.185,3.376-1.133,9.065c0.79,4.272,1.807,8.303,3.068,12.142
          c1.247-4.842,2.197-9.443,2.904-14.01c0.5-3.224,0.083-5.894-1.143-7.325C886.881,860.282,886.271,859.966,885.628,859.966z"/>
      </g>
    </g>
  </svg>
);

export default IconPdf;
